/**
 * 投放状态管理
 */
export default {
    namespaced: true,
    state: {
        launchId:'', //投放id
        launchTitle:'', //投放标题
        launchName:'', //投放名称
        launchTab:'', //投放tab
        typeId:'', //分类id
    },
    mutations: {
        /**
         * 投放id
         * @param state
         * @param launchId
         * @constructor
         */
        SET_LAUNCH_ID (state,launchId) {
            state.launchId = launchId;
        },
        /**
         * 投放tab
         * @param state
         * @param launchTab
         * @constructor
         */
        SET_LAUNCH_TAB (state,launchTab) {
            state.launchTab = launchTab;
        },
        /**
         * 投放标题
         * @param state
         * @param launchTitle
         * @constructor
         */
        SET_LAUNCH_TITLE (state,launchTitle) {
            state.launchTitle = launchTitle;
        },
        /**
         * 投放名称
         * @param state
         * @param launchName
         * @constructor
         */
        SET_LAUNCH_NAME (state,launchName) {
            state.launchName = launchName;
        },
        /**
         * 分类id
         * @param state
         * @param typeId
         * @constructor
         */
        SET_TYPE_ID (state,typeId) {
            state.typeId = typeId;
        },
    },
}
