/**
 * 用户中心状态管理
 */
export default {
    namespaced: true,
    state: {
        userName:'', //用户名
    },
    mutations: {
        /**
         * 用户名
         * @param state
         * @param userName
         * @constructor
         */
        SET_USER_NAME (state,userName) {
            state.userName = userName;
        },
    },
}
