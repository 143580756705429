/**
 * 产品状态管理
 */
export default {
    namespaced: true,
    state: {
        productId:'', //产品id
        selectList:[], //选择的产品
    },
    mutations: {
        /**
         * 产品id
         * @param state
         * @param productId
         * @constructor
         */
        SET_PRODUCT_ID (state,productId) {
            state.productId = productId;
        },
        /**
         * 选择的产品
         * @param state
         * @param selectList
         * @constructor
         */
        SET_SELECT_LIST (state,selectList) {
            state.selectList = [];
            if(selectList.length!==0){
                state.selectList = selectList;
            }
        },
    },
}
