import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/style.main.less'
import '@/style/animation.main.less'
import 'iview/dist/styles/iview.css';
import('@/assets/common/en-font/font.less')
import('@/assets/common/cn-font/font.less')
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
