import Vue from 'vue'
import Vuex from 'vuex'
import userCenter from './userCenter'
import product from './product'
import launch from './launch'
import brand from './brand'

Vue.use(Vuex)


export default new Vuex.Store({
    modules:{
        userCenter,
        product,
        launch,
        brand
    }
})
