import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
const Login = () => import('@/views/Login/login.vue')
const Catalogue = () => import('@/views/Catalogue/catalogue.vue')
const OriginalList = () => import('@/views/Original/List/list.vue')
const ProductList = () => import('@/views/Product/List/list.vue')
const ProductSelect = () => import('@/views/Product/Select/select.vue')
const ProductInfo = () => import('@/views/Product/Info/info.vue')
const ProductCheck = () => import('@/views/Product/Check/check.vue')
const LaunchList = () => import('@/views/Launch/List/list.vue')
const LaunchEdit = () => import('@/views/Launch/Edit/edit.vue')
const BrandList = () => import('@/views/Brand/List/list.vue')
const BrandEdit = () => import('@/views/Brand/Edit/edit.vue')
const SearchList = () => import('@/views/Search/List/list.vue')
const SearchEdit = () => import('@/views/Search/Edit/edit.vue')
const KeywordsList = () => import('@/views/Keywords/List/list.vue')
const KeywordsEdit = () => import('@/views/Keywords/Edit/edit.vue')
const PreList = () => import('@/views/Pre/List/list.vue')
const PreEdit = () => import('@/views/Pre/Edit/edit.vue')
const OffersList = () => import('@/views/Offers/List/list.vue')
const OffersEdit = () => import('@/views/Offers/Edit/edit.vue')
const EdmList = () => import('@/views/Edm/List/list.vue')
const ReplyEdit = () => import('@/views/Edm/ReplyEdit/replyEdit.vue')
const EdmEdit = () => import('@/views/Edm/EdmEdit/edmEdit.vue')
Vue.use(VueRouter)
/**
 * 报错显示路由频繁点击导致路由重复而报错，该报错对路由跳转功能没有任何影响。
 * @type {{}}
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/catalogue',
        name: 'Catalogue',
        component: Catalogue
    },
    {
        path: '/original/list',
        name: 'OriginalList',
        component: OriginalList,
    },
    {
        path: '/product/list',
        name: 'ProductList',
        component: ProductList,
    },
    {
        path: '/product/select',
        name: 'ProductSelect',
        component: ProductSelect,
    },
    {
        path: '/product/info',
        name: 'ProductInfo',
        component: ProductInfo,
    },
    {
        path: '/product/check',
        name: 'ProductCheck',
        component: ProductCheck,
    },
    {
        path: '/launch/list',
        name: 'LaunchList',
        component: LaunchList,
    },
    {
        path: '/launch/edit',
        name: 'LaunchEdit',
        component: LaunchEdit,
    },
    {
        path: '/brand/list',
        name: 'BrandList',
        component: BrandList,
    },
    {
        path: '/brand/edit',
        name: 'BrandEdit',
        component: BrandEdit,
    },
    {
        path: '/search/list',
        name: 'SearchList',
        component: SearchList,
    },
    {
        path: '/search/edit',
        name: 'SearchEdit',
        component: SearchEdit,
    },
    {
        path: '/keywords/list',
        name: 'KeywordsList',
        component: KeywordsList,
    },
    {
        path: '/Keywords/edit',
        name: 'KeywordsEdit',
        component: KeywordsEdit,
    },
    {
        path: '/pre/page',
        name: 'PreList',
        component: PreList,
    },
    {
        path: '/pre/edit',
        name: 'PreEdit',
        component: PreEdit,
    },
    {
        path: '/offers/list',
        name: 'OffersList',
        component: OffersList,
    },
    {
        path: '/offers/edit',
        name: 'OffersEdit',
        component: OffersEdit,
    },
    {
        path: '/edm/list',
        name: 'EdmList',
        component: EdmList,
    },
    {
        path: '/edm/reply/edit',
        name: 'ReplyEdit',
        component: ReplyEdit,
    },
    {
        path: '/edm/edit',
        name: 'EdmEdit',
        component: EdmEdit,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = 'Boxes'
    if (to.matched.length === 0) { //匹配前往的路由不存在
        from.name ? next({
        name: from.name
    }) : next('/catalogue'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到首页
    } else {
        next()
        const isLogin = Cookies.get('token') //判断是否登录
        if (!isLogin) {
            if (to.name === 'Login') {
                next()
            } else {
                next('/login')
            }
        } else {
            if (to.name === 'Login') {
                next('/catalogue')
            } else {
                next()
            }
        }
    }
});
export default router
