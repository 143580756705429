/**
 * 品牌状态管理
 */
export default {
    namespaced: true,
    state: {
        brandId:'', //品牌id
    },
    mutations: {
        /**
         * 品牌id
         * @param state
         * @param brandId
         * @constructor
         */
        SET_BRAND_ID (state,brandId) {
            state.brandId = brandId;
        },
    },
}
